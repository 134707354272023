// import React, { useEffect, useState } from "react"
import { NavLink as RouterLink } from 'react-router-dom';
import { Nav } from '../../styles/components';

type itemsProp = {
  name: string;
  url: string;
  icon: any;
};
const NavItems = ({
  items,
  navClassName,
  itemClassName,
  iconClassName = 'w-7 h-7',
  onClick,
  selectedClass = 'bg-orange-500 text-white',
}: {
  navClassName?: string;
  itemClassName?: string;
  iconClassName?: string;
  onClick?: () => void;
  items: itemsProp[];
  selectedClass?: string;
}) => {
  return (
    <div>
      <Nav className={navClassName}>
        {items.map((item: any) => {
          if (item.hidden) {
            return null;
          }
          return (
            <Nav.Item
              key={item.name}
              // to hide mobile nav on route select
              onChange={() => (onClick ? onClick() : null)}
              className={itemClassName}
              selectedClass={selectedClass}
              component={RouterLink}
              to={item.url}
              label={item.name}
              icon={
                item.icon ? <item.icon className={iconClassName} /> : undefined
              }
            />
          );
        })}
      </Nav>
    </div>
  );
};

export default NavItems;
