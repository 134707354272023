/* eslint-disable react/jsx-pascal-case */
// import Avatar from '../../styles/components/avatar/avatar';
import NavItems from './nav_with_items';
import {
  CogIcon,
  HomeIcon,
  DotsCircleHorizontalIcon,
  MailIcon,
} from '@heroicons/react/outline';
import { Query } from 'shared';
import { Fragment } from 'react';
import { classNames } from '../../styles/utils';
import { IdCheck, CarIcon } from '../../styles/icons';
import { useAuthContext } from '../../hooks';
import LogoMain from 'assets/images/checktastic.jpg';
import config from 'shared/config';

type sideProps = {
  toggle?: boolean;
};

export const topNav = ({ hiddenItemName }: { hiddenItemName: string }) => [
  {
    name: 'Startseite',
    url: '/dashboard',
    icon: HomeIcon,
    expect: true,
    hidden: hiddenItemName === 'Startseite',
  },
  {
    name: 'Eingang Scans',
    url: '/scans',
    icon: MailIcon,
    exact: true,
    hidden: hiddenItemName === 'Eingang Scans',
  },
  {
    name: 'ID Check',
    url: '/id_check',
    icon: IdCheck,
    exact: false,
    hidden: hiddenItemName === 'ID Check',
  },
  {
    name: 'Fahrzeuge',
    url: '/vehicles',
    icon: CarIcon,
    exact: false,
    hidden: hiddenItemName === 'Fahrzeuge',
  },
];
const bottomNav = [
  {
    name: 'Admin',
    url: '/setting_page',
    icon: CogIcon,
    exact: true,
  },
  {
    name: 'Mehr',
    url: '/privacy',
    icon: DotsCircleHorizontalIcon,
    expect: true,
  },
];

export const SideItems = ({
  verticalitems = true,
  onSideItemCLick,
  hiddenItemName = '',
}: {
  verticalitems?: boolean;
  onSideItemCLick?: () => void;
  hiddenItemName: string;
}) => {
  const itemClassNames = classNames(
    verticalitems ? 'flex-col' : ' ',
    'text-white hover:bg-gray-900 hover:text-pink group p-2 rounded-md flex items-center text-xs font-medium',
  );
  const iconClassName = !verticalitems
    ? 'w-7 h-7 mr-3 text-white'
    : 'w-9 h-9 mb-2 text-white';

  return (
    <Fragment>
      <div className="flex-1 flex justify-between flex-col flex-grow">
        <NavItems
          onClick={onSideItemCLick}
          iconClassName={iconClassName}
          items={topNav({ hiddenItemName })}
          navClassName="flex-1 w-full px-2 space-y-2 item-start"
          itemClassName={itemClassNames}
        />
        <NavItems
          onClick={onSideItemCLick}
          iconClassName={iconClassName}
          items={bottomNav}
          navClassName="flex-1 w-full px-2 space-y-1"
          itemClassName={itemClassNames}
        />
      </div>
    </Fragment>
  );
};
const Sidebadr: React.FC<sideProps> = ({ toggle }) => {
  const { FetchHeadquarterDataById, FetchBranchOfficeDataById } = Query();
  const auth = useAuthContext();
  const localStorageUser = window.localStorage.getItem('user');
  const parsedObje = localStorageUser
    ? JSON.parse(localStorageUser)
    : undefined;
  const user = auth.user ? auth.user : parsedObje ? parsedObje : undefined;

  const isHeadQuarter =
    user && user.user_owner_type === 'Dealer::Headquarter' ? true : false;
  const headQuarterId = user ? user.headquarter.id : undefined;
  // console.log('user', user, headQuarterId);

  const { data: headQuearterData } = FetchHeadquarterDataById({
    key: `headquarter-${headQuarterId}`,
    id: user ? user.user_owner_id : undefined,
    enabled: isHeadQuarter && user !== undefined,
  });
  const {
    data: branchOfficeData,
    //  / error: isBranchOfficeError,
    // isError: isBranchError,
  } = FetchBranchOfficeDataById({
    key: 'branch_office',
    id: user ? user.user_owner_id : undefined,
    enabled: !isHeadQuarter && user !== undefined,
  });
  const currentOfficeData = isHeadQuarter ? headQuearterData : branchOfficeData;
  // console.log('currentOfficeData', user?.plan_version);
  const avatarUrl =
    currentOfficeData && currentOfficeData.logo_image.orignal
      ? config.baseURL + currentOfficeData.logo_image.orignal
      : LogoMain;
  return (
    <div className="hidden w-30 bg-gray-800 md:block">
      <div className="w-full pb-6 flex flex-col h-full">
        <div className="flex-shrink-0 pb-3 w-32 h-36">
          <img
            className="w-full h-full object-contain object-center"
            src={avatarUrl}
            alt="headQuarter logo"
          />
        </div>
        <SideItems
          hiddenItemName={user?.plan_version === 'light' ? 'Fahrzeuge' : ''}
        />
      </div>
    </div>
  );
};

export default Sidebadr;
