import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { isMobile } from 'react-device-detect';
import { ExclamationIcon } from '@heroicons/react/solid';
import { classNames } from '../../../../styles/utils';
import { Button } from '../../../../styles/components';
import { Query } from 'shared';
//@ts-ignore
import QRCode from 'qrcode.react';
import config from 'shared/config';
import { Link } from 'react-router-dom';
import { Spinner } from '../../../../styles/icons';
//@ts-ignore
import { ActionCableConsumer } from 'react-actioncable-provider';

const QRPopup = ({
  type,
  setPassOrIdSrc,
  setModalOpen,
  setDriveLicenseSrc,
}: {
  type?: string;
  setPassOrIdSrc?: (data: any) => void;
  setModalOpen?: (open: boolean) => void;
  setDriveLicenseSrc?: (data: any) => void;
}) => {
  const [token, setToken] = useState(null);

  const {
    //@ts-ignore
    values: { decumenttype },
    // validateField,
  } = useFormikContext();
  const isDriveLice = type === 'DRIVERS_LISCENSE';
  const { SubmitDataToserver } = Query();
  const { mutateAsync, isLoading } = SubmitDataToserver({
    url: 'external_image_capturing_processes',
  });

  useEffect(() => {
    async function CreatExternalImageCaptureProcess() {
      let { data } = await mutateAsync({
        external_image_capturing_process: {
          kind: isDriveLice ? 'DRIVERS_LISCENSE' : decumenttype,
        },
      });

      setToken(data.external_image_capturing_process.token);
    }

    if (!token && (decumenttype || isDriveLice)) {
      // console.log('fetching new token =======');
      CreatExternalImageCaptureProcess();
    }
  }, [isDriveLice, token, mutateAsync, decumenttype]);

  /// this is a BroadcastChannel soulution for ID-check when user open the app from mobile or tablet
  useEffect(() => {
    const channel = new BroadcastChannel('idCheck-data');

    channel.onmessage = (event) => {
      // console.log('🚀 ~ event.data', event);
      onReciveImagesFromMobile(event.data);
    };

    return () => {
      channel.close();
    };
    // eslint-disable-next-line
  }, []);

  // console.log('isValid', isValid);
  const refactoredWebsocketData = (data: any) => {
    return data.external_image_capturing_process.payload.identity_document_images.reduce(
      (
        acc: any,
        images: {
          image: {
            imageData: string;
            pixelHeight: number;
            pixelWidth: number;
          };
          imageType: String;
          pageType: String;
        },
      ) => {
        const visible1 =
          images.pageType === 'front' && images.imageType === 'visible'
            ? {
                'visible1-base64': images.image.imageData,
                'visible1-height': images.image.pixelHeight,
                'visible1-width': images.image.pixelWidth,
              }
            : null;

        const visible2 =
          images.pageType === 'back' && images.imageType === 'visible'
            ? {
                'visible2-base64': images.image.imageData,
                'visible2-height': images.image.pixelHeight,
                'visible2-width': images.image.pixelWidth,
              }
            : null;

        const flash1 =
          images.pageType === 'front' && images.imageType === 'flash'
            ? {
                'flash1-base64-front': images.image.imageData,
              }
            : null;

        const flash2 =
          images.pageType === 'back' && images.imageType === 'flash'
            ? {
                'flash2-based64-back': images.image.imageData,
              }
            : null;

        // Combine non-null properties into a single object
        return Object.assign({}, acc, visible1, visible2, flash1, flash2, {
          cropped: 0,
          'device-type': 5,
        });
      },
      {},
    );
  };

  const refactoredboadcastData = (data: any) => {
    const objectdata =
      data.external_image_capturing_process.payload.identity_document_images.reduce(
        (
          acc: any,
          images: {
            cropped: number;
            identity_document_side: string;
            identity_document_type: string;
            image: string;
            image_type: string;
            height: number;
            width: number;
          },
        ) => {
          const visible1 =
            images.identity_document_side === 'front_side' &&
            images.image_type === 'visible'
              ? {
                  'visible1-base64': images.image,
                  'visible1-height': images.height,
                  'visible1-width': images.width,
                }
              : null;

          const visible2 =
            images.identity_document_side === 'back_side' &&
            images.image_type === 'visible'
              ? {
                  'visible2-base64': images.image,
                  'visible2-height': images.height,
                  'visible2-width': images.width,
                }
              : null;

          const flash1 =
            images.identity_document_side === 'front_side' &&
            images.image_type === 'flash'
              ? {
                  'flash1-base64-front': images.image,
                }
              : null;

          const flash2 =
            images.identity_document_side === 'back_side' &&
            images.image_type === 'flash'
              ? {
                  'flash2-based64-back': images.image,
                }
              : null;

          // Combine non-null properties into a single object
          return Object.assign({}, acc, visible1, visible2, flash1, flash2, {
            cropped: 0,
            'device-type': 5,
          });
        },
        {},
      );
    return objectdata;
  };
  const onReciveImagesFromMobile = (mobileData: any) => {
    // console.log('reciving new message', mobileData);
    // in the next line we are using reduce to keep the  object structure
    // as it is in the first version ( so the file new_legitimation > index.ts does't have to change a lot) because changing it will require a lot if time and will make the estimation hight for this feuture

    const dataObject = isMobile
      ? refactoredboadcastData(mobileData)
      : refactoredWebsocketData(mobileData);
    // console.log('🚀 ~ onReciveImagesFromMobile ~ dataObject:', dataObject);
    if (
      mobileData &&
      mobileData.external_image_capturing_process.payload
        .identity_document_images.length > 0
    ) {
      if (isDriveLice && typeof setDriveLicenseSrc === 'function') {
        // console.log('set setDriveLicenseSrc', mobileData);
        setDriveLicenseSrc(dataObject);
        // if (isMobile) {
        //   validateField('');
        // }
        setToken(null);
      } else {
        // console.log('set setPassOrIdSrc', mobileData);

        //  const flashFrontImage = flashImage.length &&
        //@ts-ignore
        setPassOrIdSrc(dataObject);
        setToken(null);
      }

      //@ts-ignore
      setModalOpen(false);
    }
  };
  // console.log(
  //   'link',
  //   `${config.mobileIdCheckUrl}id_scan_mobile/${token}?documentType=${
  //     isDriveLice ? type : decumenttype
  //   }`,
  // );
  if (isLoading)
    return (
      <div className="h-96 items-center flex justify-center align-center w-full">
        <Spinner className="text-orange-700" />
      </div>
    );

  if (!decumenttype || !token)
    return (
      <div className="h-80 rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Der Ausweistyp wurde nicht ausgewählt.
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>Bitte wählen Sie einen Ausweistyp und starten Sie erneut</p>
            </div>
          </div>
        </div>
      </div>
    );
  // console.log(
  //   'value',
  //   `${config.mobileIdCheckUrl}id_scan_mobile/${token}?documentType=${
  //     isDriveLice ? type : decumenttype
  //   }`,
  // );
  return (
    <div
      id="content"
      className="w-full overflow-y-auto rounded-xl flex items-center">
      <div
        className={classNames(
          'text-base text-center transform transition w-full flex',
        )}>
        <div className="w-full flex flex-col">
          <ActionCableConsumer
            channel={{
              channel: 'ExternalImageCapturingProcessNotificationsChannel',
              token: token,
            }}
            // onError={() => console.log('error socket')}
            // onConnected={() => console.log('connected')}
            // onRejected={() => console.log('onRejected socket')}
            // onInitialized={() => console.log('onInitialized')}
            onReceived={(data: any) => onReciveImagesFromMobile(data)}>
            {isMobile ? (
              <div className="mx-2">
                <div className="max-w-xl mx-auto py-4">
                  <h2 className="text-gray-700  antialiased tracking-wide font-semibold text-center">
                    Wir haben festgestellt, dass Sie checktastic gerade auf
                    einem Smartphone oder Tablet nutzen. Sie können die
                    Aufnahmen für den ID-Check in einem neuen Fenster starten
                    und nach Abschluss wieder zu diesem Fenster zurückkehren, um
                    die Legitimationsprüfung abzuschließen.
                  </h2>
                </div>

                <Link
                  // to={{ pathname: '/my-route', search: '?param1=value1&param2=value2' }}
                  to={`/id_scan_mobile/${token}?documentType=${
                    isDriveLice ? type : decumenttype
                  }&device_type=${'mobile'}`}
                  target={'_blank'}>
                  <Button
                    onClick={() => null}
                    label="Start"
                    type="primary"
                    className="w-full justify-center my-5"
                  />
                </Link>
              </div>
            ) : (
              <div className="overflow-hidden max-w-lg mx-auto flex flex-col items-center">
                <div className="rounded-lg p-6">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-yellow-800">
                        Dieses Fenster bitte NICHT schließen
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="aspect-w-2 aspect-h-3 rounded-lg bg-gray-100">
                  <QRCode
                    value={`${
                      config.mobileIdCheckUrl
                    }id_scan_mobile/${token}?documentType=${
                      isDriveLice ? type : decumenttype
                    }&device_type=${'desktop'}`}
                  />
                </div>
              </div>
            )}

            {!isMobile && (
              <div className="max-w-xl mx-auto py-4">
                <h2 className="text-gray-700  antialiased tracking-wide font-semibold">
                  Öffnen Sie die Kamera auf Ihrem Smartphone oder Tablet und
                  folgen Sie dem Link. Ihr mobiles Gerät führt Sie Schritt für
                  Schritt durch den Aufnahmeprozess. Bitte dieses Fenster
                  während den Aufnahmen NICHT schließen. Nach dem die Bilder
                  übertragen wurden, schließt es automatisch.
                </h2>
                {/* <Button
                type="primary"
                label="fetch mobile images"
                onClick={() => {
                  refetch();
                  onReciveImagesFromMobile();
                }}
              /> */}
              </div>
            )}
          </ActionCableConsumer>
        </div>
      </div>
    </div>
  );
};

export default QRPopup;
