import { useState, useRef } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { Disclosure } from '@headlessui/react';
import LogoMain from 'assets/images/checktastic.jpg';
import Steps from './steps';
import FormAction from './action';
import idCardBack from 'assets/images/id-back.jpg';
import { Query } from 'shared';
import idCardFront from 'assets/images/id-front.jpg';
import flashIDFront from 'assets/images/id-flash-back.jpg';
import flashIDBack from 'assets/images/id-flash-back.jpg';

import successImgUrl from 'assets/images/success.png';
import { useStores } from 'shared';

import HandleStepWithCamera, {
  IHandleStepWithCameraProp,
} from './step_content_with_camer';
import { ErrorPage } from '../../../../shared';
import { Spinner } from '../../../../../styles/icons';

// type ComposedProps = RouteComponentProps<{
//   documentType: 'PASSPORT' | 'DRIVERS_LISCENSE' | 'IDENTITY_CARD';
// }>;

const IDCheckMobile = ({ location }: RouteComponentProps) => {
  const [openTab, setOpenTab] = useState<string | number>(0);
  const [passOrDrivelicenseError, setPassportOrDriveRequestError] =
    useState(false);
  const [idCardError] = useState(false);
  const [isCameraMode, setCameraMode] = useState(false);
  const [src, setSrc] = useState<{
    url: string;
    height: number;
    width: number;
  } | null>(null);
  const [canvasData, setCanvasData] = useState(null);
  const { ui } = useStores();
  const { search } = location;
  const { documentType, device_type } = queryString.parse(search);
  const btnRef = useRef();
  const isIDCard =
    documentType === 'IDENTITY_CARD' || documentType === 'RESIDENCE_PERMIT';
  //@ts-ignore
  let { token } = useParams();

  // console.log('isDesktop src=======', device_type);
  // const frontImageUrl = openTab === 0 && !isIDCard ? passImage : idCardFront;
  const { FetchExternalImageCaptureProcess, SubmitDataToserver } = Query();
  const { data, isLoading, isError } = FetchExternalImageCaptureProcess({
    key: `mobile-data-${token}`,
    enabled: true,
    token: token,
  });

  const { mutateAsync, isLoading: isSendingData } = SubmitDataToserver({
    url: `external_image_capturing_processes/${token}/capture`,
  });

  const handleFrontSideDocumentRequest = async (data: any) => {
    const DataToBeSendToServer = {
      external_image_capturing_process: {
        identity_document_images_attributes: data,
      },
    };

    if (device_type === 'desktop') {
      try {
        await mutateAsync(DataToBeSendToServer);
      } catch (e) {
        setPassportOrDriveRequestError(true);
      }
    } else {
      // console.log('sending data to browser', DataToBeSendToServer);
      const channel = new BroadcastChannel('idCheck-data');
      channel.postMessage({
        external_image_capturing_process: {
          kind: documentType,
          payload: { identity_document_images: data },
          status: 'completed',
        },
      });
    }
  };
  const isExtraImageWithFlash =
    data && data.external_image_capturing_process.uses_copy_detection;

  const stepsInfo = !isExtraImageWithFlash
    ? `Schritt ${Number(openTab) + 1} von ${isIDCard ? '3' : '2'}`
    : `Schritt ${Number(openTab) + 1} von ${isIDCard ? '5' : '3'}`;

  // in the next line we check if the data should be submitted to the server based on the openTab and if should have a lash light or not
  const submitDataTOsocketServer = isExtraImageWithFlash
    ? isIDCard
      ? openTab === 3
      : openTab === 1
    : isIDCard
    ? openTab === 1
    : openTab === 0;

  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSaveImageToWebApp = async () => {
    if (ui.imageSrcMobileIDCheck && ui.imageSrcMobileIDCheck.length) {
      await handleFrontSideDocumentRequest(ui.imageSrcMobileIDCheck);
      ui.restImages();
    }
  };
  const getText = (type: string): { title: string; subtitle: string } => {
    const isError = passOrDrivelicenseError || idCardError;
    switch (type) {
      // CAPTURE
      case 'IDENTITY_CARD_PASSPORT_FRONT_NO_FLASH':
        return {
          title: isIDCard
            ? 'Vorderseite Personalausweis'
            : 'Vorderseite Reisepass oder Führerschein',
          subtitle:
            'Halten Sie Ihr Telefon oder Tablet hochkant. Bitte achten Sie darauf, dass das Dokument zentriert ist und die Abstände rechts und links etwa gleich sind. Siehe Beispielbild. Wichtig: Stellen Sie sicher, dass alle Details gut lesbar sind.',
        };
      // CAPTURE
      case 'IDENTITY_CARD_BACK_NO_FLASH':
        return {
          title: 'Rückseite Personalausweis',
          subtitle:
            'Halten Sie Ihr Telefon oder Tablet hochkant. Bitte achten Sie darauf, dass das Dokument zentriert ist und die Abstände rechts und links etwa gleich sind. Siehe Beispielbild. Wichtig: Stellen Sie sicher, dass alle Details gut lesbar sind.',
        };
      // CAPTURE
      case 'IDENTITY_CARD_FRONT_WITH_FLASH':
        return {
          title: 'Vorderseite Personalausweis mit BLITZ',
          subtitle:
            'Aktivieren Sie den Blitz Ihres Mobilgerätes und fotografieren Sie die Vorderseite Ihres Personalausweises erneut.',
        };
      // CAPTURE
      case 'IDENTITY_CARD_BACK_WITH_FLASH':
        return {
          title: 'Rückseite Personalausweis mit BLITZ',
          subtitle:
            'Aktivieren Sie den Blitz Ihres Mobilgerätes und fotografieren Sie die Rückseite Ihres Personalausweises erneut.',
        };
      // CAPTURE
      case 'DRIVERS_LISCENSE_PASSPORT_FRONT_WITH_FLASH':
        return {
          title: 'Vorderseite Reisepass oder Führerschein mit BLITZ',
          subtitle:
            'Aktivieren Sie den Blitz Ihres Mobilgerätes und fotografieren Sie die Vorderseite Ihres Führerschein erneut.',
        };
      //       // Last Step (Success or Error)
      //  case 'DRIVERS_LISCENSE_PASSPORT_LASTSTEP_NO_FLASH':
      //       return {
      //         title: passOrDrivelicenseError ? 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut':'Aufnahmeprozess abgeschlossen.',
      //         subtitle: passOrDrivelicenseError ? 'Es konnten keine Bilder übertragen werden. Bitte schließen Sie dieses Fenster und versuchen Sie es später erneut.' :  'Sie können diese Seite jetzt schließen. Ihre Aufnahmen wurden übertragen.',
      //       }
      //     // Last Step (Success or Error)
      //   case 'IDENTITY_CARD_LASTSTEP_NO_FLASH':
      //         return {
      //           title: idCardError ? 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut':'Aufnahmeprozess abgeschlossen.',
      //           subtitle: idCardError ? 'Es konnten keine Bilder übertragen werden. Bitte schließen Sie dieses Fenster und versuchen Sie es später erneut.' :  'Sie können diese Seite jetzt schließen. Ihre Aufnahmen wurden übertragen.',
      //         }
      //            // Last Step (Success or Error)
      //   case 'IDENTITY_CARD_LASTSTEP_WITH_FLASH':
      //     return {
      //       title: idCardError ? 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut':'Aufnahmeprozess abgeschlossen.',
      //       subtitle: idCardError ? 'Es konnten keine Bilder übertragen werden. Bitte schließen Sie dieses Fenster und versuchen Sie es später erneut.' :  'Sie können diese Seite jetzt schließen. Ihre Aufnahmen wurden übertragen.',
      //     }
      default:
        return {
          title: isError
            ? 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut'
            : 'Aufnahmeprozess abgeschlossen.',
          subtitle: isError
            ? 'Es konnten keine Bilder übertragen werden. Bitte schließen Sie dieses Fenster und versuchen Sie es später erneut.'
            : 'Sie können diese Seite jetzt schließen. Ihre Aufnahmen wurden übertragen.',
        };
    }
  };

  /*{
    handleStepWithCameraProps is a function that return the porps for 
    HandleStepWithCamera Component to reduce code repeation.
  }*/
  const handleStepWithCameraProps = (
    type: string,
  ): IHandleStepWithCameraProp => {
    const { title, subtitle } = getText(type);

    return {
      //@ts-ignore
      documentType,
      imageSrcType: type,
      stepsInfo,
      isCameraMode,
      setCameraMode,
      src,
      setSrc,
      subtitle,
      title,
      isSendingData,
      canvasData,
      setCanvasData,
    };
  };
  // console.log('canvasData', canvasData);
  const handleNextStep = async () => {
    //@ts-ignore
    ui.setImages(canvasData.timeStamp);
    //@ts-ignore
    setOpenTab((prev: number) => {
      if (isIDCard) {
        return Number(prev) + 1;
      } else if (
        documentType === 'PASSPORT' ||
        documentType === 'DRIVERS_LISCENSE'
      ) {
        return isExtraImageWithFlash ? Number(prev) + 1 : 1;
      } else {
        return 0;
      }
    });
    if (submitDataTOsocketServer) {
      await handleSaveImageToWebApp();
    }
    setCameraMode(false);
    setSrc(null);
  };
  if (isLoading)
    return (
      <div className="h-96 items-center flex justify-center align-center w-full">
        <Spinner className="text-orange-700" />
      </div>
    );
  if (
    isError ||
    !data ||
    (data && data.external_image_capturing_process.token !== token) ||
    (data && data.external_image_capturing_process.status !== 'pending')
  )
    return <ErrorPage />;
  return (
    <div className="h-screen">
      <div className="bg-gray-800">
        <Disclosure as="nav" className="bg-gray-800">
          {() => (
            <>
              <div className="max-w-7xl mx-auto">
                <div className="border-b border-gray-700">
                  <div className="flex items-center justify-between h-16 px-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-16 w-auto"
                        src={LogoMain}
                        alt="mainLogo"
                      />
                    </div>
                    <div>
                      <p className="block ml-2 text-center  font-bold text-orange-500">
                        Checktastic ID Checks
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>
      </div>

      <main className="w-full">
        <div className="px-3 pt-2 flex flex-col">
          <Steps
            openTab={openTab}
            //@ts-ignore
            documentType={documentType}
            isExtraImageWithFlash={isExtraImageWithFlash}
          />
          <>
            {/* Personalausweis and Reisepass oder Führerschein Front */}
            {openTab === 0 && (
              <HandleStepWithCamera
                {...handleStepWithCameraProps(
                  'IDENTITY_CARD_PASSPORT_FRONT_NO_FLASH',
                )}
                url={idCardFront}
              />
            )}

            {/* Personalausweis Rückseite */}
            {openTab === 1 && isIDCard && (
              <HandleStepWithCamera
                {...handleStepWithCameraProps('IDENTITY_CARD_BACK_NO_FLASH')}
                url={idCardBack}
              />
            )}
            {/* Personalausweis  Front with flash */}
            {openTab === 2 && isExtraImageWithFlash && isIDCard && (
              <HandleStepWithCamera
                //@ts-ignore
                {...handleStepWithCameraProps('IDENTITY_CARD_FRONT_WITH_FLASH')}
                url={flashIDFront}
              />
            )}
            {/* Personalausweis Rückseitet with flash */}
            {openTab === 3 && isExtraImageWithFlash && isIDCard && (
              <HandleStepWithCamera
                {...handleStepWithCameraProps('IDENTITY_CARD_BACK_WITH_FLASH')}
                url={flashIDBack}
              />
            )}
            {/* Reisepass oder Führerschein LAST step with no flash */}
            {openTab === 1 &&
              (documentType === 'PASSPORT' ||
                documentType === 'DRIVERS_LISCENSE') &&
              !isExtraImageWithFlash && (
                <HandleStepWithCamera
                  {...handleStepWithCameraProps(
                    'DRIVERS_LISCENSE_PASSPORT_LASTSTEP_NO_FLASH',
                  )}
                  isError={passOrDrivelicenseError}
                  isLastStep={openTab === 1}
                  url={successImgUrl}
                />
              )}
            {/* Reisepass oder Führerschein front with flash */}
            {openTab === 1 &&
              (documentType === 'PASSPORT' ||
                documentType === 'DRIVERS_LISCENSE') &&
              isExtraImageWithFlash && (
                <HandleStepWithCamera
                  {...handleStepWithCameraProps(
                    'DRIVERS_LISCENSE_PASSPORT_FRONT_WITH_FLASH',
                  )}
                  url={flashIDFront}
                />
              )}
            {/* Personalausweis lastt step with no flash */}
            {openTab === 2 && !isExtraImageWithFlash && (
              <HandleStepWithCamera
                {...handleStepWithCameraProps(
                  'IDENTITY_CARD_LASTSTEP_NO_FLASH',
                )}
                isError={idCardError}
                isLastStep={openTab === 2}
                url={successImgUrl}
              />
            )}
            {/* Personalausweis lastt step with  flash */}
            {openTab === 4 && (
              <HandleStepWithCamera
                {...handleStepWithCameraProps(
                  'IDENTITY_CARD_LASTSTEP_WITH_FLASH',
                )}
                isError={idCardError}
                isLastStep={openTab === 4}
                url={successImgUrl}
              />
            )}
            {/* Reisepass oder Führerschein last step with flash */}
            {openTab === 2 &&
              (documentType === 'PASSPORT' ||
                documentType === 'DRIVERS_LISCENSE') &&
              isExtraImageWithFlash && (
                <HandleStepWithCamera
                  {...handleStepWithCameraProps(
                    'DRIVERS_LISCENSE_PASSPORT_LASTSTEP_WITH_FLASH',
                  )}
                  isError={passOrDrivelicenseError}
                  isLastStep={openTab === 2}
                  url={successImgUrl}
                />
              )}

            {/* form action no  flash */}
            {openTab !== 2 && !isExtraImageWithFlash && (
              <div className={'py-1'}>
                <FormAction
                  ref={btnRef}
                  isLoading={isSendingData}
                  hideBtn={!!src}
                  openTab={openTab}
                  handleNextStep={handleNextStep}
                />
              </div>
            )}
            {/* form action with flash */}
            {openTab !== 4 && isExtraImageWithFlash && (
              <div className={'py-1'}>
                <FormAction
                  ref={btnRef}
                  isExtraImageWithFlash={isExtraImageWithFlash}
                  isLoading={isSendingData}
                  hideBtn={!!src}
                  openTab={openTab}
                  handleNextStep={handleNextStep}
                />
              </div>
            )}
          </>
        </div>
      </main>
    </div>
  );
};

export default IDCheckMobile;
